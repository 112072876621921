import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Inject,
  Input,
} from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import {
  getUserProfile,
  resetProfileState,
  updateUserProfile,
} from '../../../store/Authentication/authentication.actions';
import { Store, select } from '@ngrx/store';
import {
  getUpdatedUserProfileResponse,
  getUserProfileFailure,
  userDetails,
} from '../../../store/Authentication/authentication-selector';
import { LoaderService } from '../../../core/services/loader.service';
import { UserProfile } from '../../../shared/modals/common';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Validations } from '../../../shared/constants/validations';
import { SharedService } from '../../../shared/shared.service';
import { SweetAlertIcon } from '../../../shared/constants/enum';
@Component({
  selector: 'app-rp-user-profile',
  templateUrl: './rp-user-profile.component.html',
  styleUrl: './rp-user-profile.component.scss',
})
export class RpUserProfileComponent {
  mode: any;
  element: any;
  nameRegex = '^[a-zA-Z]+(?: [a-zA-Z]+)?(?: [a-zA-Z]+)?$';
  cookieValue: any;
  flagvalue: any;
  countryName: any;
  valueset: any;
  theme: any;
  submitted: boolean = false;
  error$: any;
  url: any = '';
  profileData: UserProfile = {
    firstName: '',
    lastName: '',
    email: '',
    countryCodeId: '',
    secondaryCountryCodeId: '',
    secondaryEmail: '',
    phoneNumber: 0,
    fileUrl: '',
    secondaryPhoneNumber: '',
  };
  prifileValidations = Validations.ProfilePage;
  profileForm!: UntypedFormGroup;
  @Input() configData: any;
  constructor(
    private router: Router,
    private store: Store,
    public translate: TranslateService,
    private modalService: BsModalService,
    private loaderService: LoaderService,
    private formBuilder: UntypedFormBuilder,
    private sharedService: SharedService
  ) {

  }

  resetUpdateProject() {
    this.store.dispatch(resetProfileState());
  }

  getStoreInfo() {
    this.resetUpdateProject();
    this.error$ = this.store.pipe(select(getUserProfileFailure));
    let profileData$ = this.store.pipe(select(userDetails));
    let updatedProfileData$ = this.store.pipe(
      select(getUpdatedUserProfileResponse)
    );
    // console.log(this.sendOtpResponse$);

    updatedProfileData$.subscribe((res: any) => {
      this.loaderService.setLoading(false);
      if (res) {
        this.modalService.hide();
        this.sharedService.showMessageDialog(
          'Success',
          res.message,
          SweetAlertIcon.SUCCESS
        );
      }
      this.store.dispatch(getUserProfile());
      // console.log('responseprofie', res, this.url);
    });

    profileData$.subscribe((res: any) => {
      this.loaderService.setLoading(false);
      this.profileData = res;

      if (res && res.firstName) {
        this.profileForm.patchValue({
          firstName: this.profileData.firstName,
          lastName: this.profileData.lastName,
          emailAddress: this.profileData.email,
          countryCode: this.profileData.countryCodeId,
          mobileNumber: this.profileData.phoneNumber,
          secondaryEmail: this.profileData.secondaryEmail,
          profileImg: this.profileData.fileUrl,
          secondaryCountryCode: this.profileData.secondaryCountryCodeId,
          secondaryPhoneNumber: this.profileData.secondaryPhoneNumber,
          // Patch other form controls as needed
        });
        this.url = this.profileData.fileUrl;
      }

      this.configData?.data?.countryCodes.forEach((code: any) => {
        if (this.profileData.secondaryCountryCodeId == code.id) {
          this.valiadationMobile(code);
          console.log("dhsad")
        }

      })

      console.log('responseprofie', res, this.url);
    });

    this.error$.subscribe((res: any) => {
      this.loaderService.setLoading(false);
      console.log('err', res);
    });
  }

  get f() {
    return this.profileForm.controls;
  }

  closePopUp() {
    const profileData = {
      firstName: 'John',
      lastName: 'Doe',
      email: 'john@example.com',
      mobileNumber: '1234567890',
      secondMobileNumber: '0987654321',
      secondaryCountryCode: ''
    };

    // this.profileSaved.emit(profileData);
    this.modalService.hide();
  }

  ngOnInit() {

    console.log(this.profileData);
    this.profileForm = this.formBuilder.group({
      firstName: [
        '',
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(30),
          Validators.pattern(this.nameRegex),
        ],
      ],
      lastName: [
        '',
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(30),
          Validators.pattern(this.nameRegex),
        ],
      ],
      profileImg: [''],
      emailAddress: [
        { value: '', disabled: true },
        ,
        [Validators.required, Validators.email],
      ],
      countryCode: [{ value: '', disabled: true },],
      mobileNumber: [
        { value: '', disabled: true },
        ,
        [Validators.required, Validators.pattern(/^\d+$/),],
      ],
      secondaryEmail: [
        ,
        [
          Validators.pattern(
            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
          ),
        ],
      ],
      secondaryCountryCode: [null],
      secondaryPhoneNumber: []
      /* secondaryPhoneNumber: [
        ,
        [
          Validators.pattern(/^\d+$/), Validators.minLength(10), Validators.maxLength(10)
        ],
      ], */
      // Add more form controls as needed
    });
    this.loaderService.setLoading(true);
    this.store.dispatch(getUserProfile());
    this.getStoreInfo();
  }

  selectedCountyCode: any;
  valiadationMessage: String = ''
  valiadationMobile(event: any) {
    // console.log(event)
    this.selectedCountyCode = event.code;
    switch (this.selectedCountyCode) {
      /*  case '+971'://United Arab Emirates
         this.f['secondaryPhoneNumber'].setValidators([Validators.required, Validators.maxLength(9), Validators.minLength(9), Validators.pattern(/^05[0-9]{8}$/),]);
         this.f['secondaryPhoneNumber'].updateValueAndValidity();
         this.valiadationMessage = 'PhoneNumber Should Conatines min 9 and max 9 characters'
 
         break; */
      case '+91': // India
        this.f['secondaryPhoneNumber'].setValidators([Validators.required, Validators.maxLength(10), Validators.minLength(10), Validators.pattern(/^[6-9]\d{9}$/),]);
        this.f['secondaryPhoneNumber'].updateValueAndValidity();
        this.valiadationMessage = 'Mobile Number should contain 10 characters'
        break;
      /* case '+86'://China
        this.f['secondaryPhoneNumber'].setValidators([Validators.required, Validators.maxLength(11), Validators.minLength(11), Validators.pattern(/^1[3-9]\d{9}$/),]);
        this.f['secondaryPhoneNumber'].updateValueAndValidity();
        this.valiadationMessage = 'PhoneNumber Should Conatines min 11 and max 11 characters'
        break; */


    }
  }
  onSubmit(): void {
    if (this.f['secondaryPhoneNumber'].value != "" && this.f['secondaryCountryCode'].value == null) {
      return
    }
    this.submitted = true;
    console.log(this.profileForm.valid)
    if (this.profileForm.valid) {
      this.loaderService.setLoading(true);
      // Process form data (send to backend, etc.)
      console.log(this.profileForm.value);
      this.store.dispatch(updateUserProfile(this.profileForm.value));
    }
  }



  onSelectFile(event: any): void {
    const file = event.target.files[0];
    console.log(file);

    if (file && file.type.startsWith('image/')) {
      this.profileForm.patchValue({
        profileImg: file,
      });
      this.profileForm.get('profileImg')?.updateValueAndValidity();

      // Display the selected image preview
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.url = reader.result;
      };
    } else {
      this.sharedService.showMessageDialog(
        'Error',
        'Please select a valid image file',
        SweetAlertIcon.ERROR
      );
    }
  }
}
